import React, { useState, useRef, useEffect } from "react";
import view from "./dropdown.module.scss";

const DropdownOption = ({ type, placeholder, values, addFilter, label, sorter }) => {
  const [option, setOption] = useState(placeholder);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if a click happens outside
      }
    };

    window.addEventListener('click', handleGlobalClick);

    // Cleanup - remove the listener when the component unmounts
    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, []);

  const toggleDropdown = (event) => {
    event.stopPropagation();  // Stop event propagation
    setIsOpen(!isOpen);
  };

  const renderOptions = () => {
    const keys = Object.keys(values);
    const sortedKeys = sorter ? keys.sort(sorter) : keys;

    if (!isOpen) {
      return null; // Return nothing if the dropdown is closed
    }

    return sortedKeys.map((key, idx) => (
      <li
        key={idx}
        className={view["sub-dropdown__item"]}
        onClick={() => {
          onSelected(key);
        }}
      >
        {`${key} (${values[key]})`}
      </li>
    ));
  };

  const onSelected = (value) => {
    if (value !== placeholder) {
      if (type === "new" && value === "Used & Certified") {
       addFilter({ value: "ALL", type: "certified" });
      }else if (type === "new" && value === "Used") {
        addFilter({ value: "not-certified", type: "certified" });
      }
      else if (type === "new" && value === "Certified") {
        addFilter({ value: "", type: "certified" });
        addFilter({ value: "", type: "new" });
      }
      addFilter({ value, type });
    } else {
      if (placeholder === "New") {
        addFilter({ value: "New", type: "new" });
        addFilter({ value: "", type: "certified" });
      }else{
        addFilter({ value: "", type });
      }
    }
    setOption(value);
    setIsOpen(false); // Close the dropdown after an option is selected directly
    document.activeElement.blur();
  };  

  return (
    <ul className={view["dropdowns"]} style={{ padding: 0 }} ref={dropdownRef}>
      <li className={view["dropdown"]} style={{ width: "100%" }}>
        <a tabIndex="0" className={view["dropdown__inner"]} onClick={toggleDropdown}>
          <p className={view["dropdown__name"]}>{label && label}</p>
          <p className={view["dropdown__value"]}>{option}</p>
        </a>
        {isOpen && (
          <ul tabIndex="0" className={view["sub-dropdown"]}>
            <li
              className={view["sub-dropdown__item"]}
              onClick={() => {
                onSelected(placeholder);
              }}
            >
              {placeholder}
            </li>
            {renderOptions()}
          </ul>
        )}
      </li>
    </ul>
  );
};

export default DropdownOption;
